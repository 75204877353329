var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advent-calendar" },
    [
      _c(
        "div",
        [
          _c("AdventCalendarBlock", {
            attrs: {
              calendar: _vm.calendar,
              availableCoupon: _vm.availableCoupon
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "pt-6" },
        [
          _c(
            "v-btn",
            {
              staticClass: "more-button",
              attrs: {
                color: "white",
                "x-large": "",
                rounded: "",
                block: "",
                depressed: "",
                to: "/coupon/calendario-avvento-info"
              }
            },
            [_vm._v(_vm._s(_vm.$t("coupons.welcomeDiscounts.moreInfo")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }